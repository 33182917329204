var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.data && !_vm.loading)?_c('tfoms-panel',_vm._b({attrs:{"title":"Результаты экспертизы","small":""}},'tfoms-panel',_vm.$attrs,false),[_c('div',{staticClass:"analysis-tfoms_expertise-table analysis-tfoms__table analysis-tfoms__base-table"},[_c('base-table-full',{attrs:{"dataSource":_vm.data,"headers":[
          { text: 'Полис', value: 'Polis' },
          {
            text: 'Дата экспертизы',
            value: 'ExpertiseDate',
            dataType: 'Date',
            displayText: _vm.$options.filters.DateShort,
          },
          { text: 'Вид', value: 'KindText' },
          { text: 'Код', value: 'ViolationCode' },
          { text: 'Наименование', value: 'Name' },
          {
            text: 'Неоплата',
            value: 'NonPaymentValue',
            displayText: function (e) { return this$1.$options.filters.numberSplit(e); },
          },
          {
            text: 'Штрафы',
            value: 'FineValue',
            displayText: function (e) { return this$1.$options.filters.numberSplit(e); },
          } ],"hide-default-footer":"","notShowSelect":"","nested":"","not-filter":"","notShowSettings":"","sort-by":['ExpertiseDate']},on:{"click:row":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"item.NonPaymentValue",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numberSplit")(item.NonPaymentValue))+" ")]}},{key:"item.FineValue",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numberSplit")(item.FineValue))+" ")]}}],null,false,1021317449)})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }