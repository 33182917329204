<template>
  <div>
    <tfoms-panel
      v-if="data && !loading"
      title="Результаты экспертизы"
      small
      v-bind="$attrs"
    >
      <div
        class="analysis-tfoms_expertise-table analysis-tfoms__table analysis-tfoms__base-table"
      >
        <base-table-full
          :dataSource="data"
          :headers="[
            { text: 'Полис', value: 'Polis' },
            {
              text: 'Дата экспертизы',
              value: 'ExpertiseDate',
              dataType: 'Date',
              displayText: $options.filters.DateShort,
            },
            { text: 'Вид', value: 'KindText' },
            { text: 'Код', value: 'ViolationCode' },
            { text: 'Наименование', value: 'Name' },
            {
              text: 'Неоплата',
              value: 'NonPaymentValue',
              displayText: (e) => this.$options.filters.numberSplit(e),
            },
            {
              text: 'Штрафы',
              value: 'FineValue',
              displayText: (e) => this.$options.filters.numberSplit(e),
            },
          ]"
          hide-default-footer
          notShowSelect
          nested
          not-filter
          notShowSettings
          :sort-by="['ExpertiseDate']"
          @click:row="$emit('input', $event)"
        >
          <template slot="item.NonPaymentValue" slot-scope="{ item }">
            {{ item.NonPaymentValue | numberSplit }}
          </template>
          <template slot="item.FineValue" slot-scope="{ item }">
            {{ item.FineValue | numberSplit }}
          </template>
        </base-table-full>
      </div>
    </tfoms-panel>
  </div>
</template>
<script>
import BaseTableFull from "@/components/base/BaseTableFull";
import tfomsTab from "../mixins/tfomsTab";

export default {
  components: {
    BaseTableFull,
    tfomsPanel: () => import("@/components/analysis/panel.vue"),
  },
  mixins: [tfomsTab],
  props: {
    value: { type: Object, default: null },
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  watch: {},
  methods: {
    async init() {},
  },
};
</script>
<style lang="scss"></style>
